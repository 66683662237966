<template>
    <div class=" h-full w-full line-heigh  flex justify-center items-center text-black">
        <v-form class="w-1/2">
            <div class="font-bold text-lg">
                Pay with card
            </div>
            <div>
                <div class="text-sm">Email</div>
                <v-text-field v-model="email" class="text-black" label="" solo></v-text-field>
            </div>
            <div>
                <div class="text-sm">Card information</div>
                <div class="card-group">
                    <div id="card-number"></div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1" id="card-expiry"></div>
                        <div class="col-span-1" id="card-cvc"></div>
                    </div>
                </div>
                <div id="card-error"></div>
            </div>
            <div class="mt-4">
                <div class="text-sm">Name on card</div>
                <v-text-field label="" class="text-black" v-model="nameCard" solo></v-text-field>
            </div>
            <div>
                <div class="text-sm">Country or region</div>
                <v-select label="" v-model="selectedCountry" :items="countryData" class="text-black" solo></v-select>
            </div>
            <div class="checkbox">
                <v-checkbox class="border-0" v-model="accpeted" color="#a1a1a1" hide-details="true" :ripple="false">
                    <template slot="label">
                        <span class="font-semibold">Securely save my infomation for 1-click checkout</span>
                    </template>
                </v-checkbox>
            </div>
            <v-btn class="w-full button mt-4 text-white" color="rgb(99 102 241)" id="custom-button"
                @click="createToken">Subcription</v-btn>
        </v-form>
    </div>
</template>

<script>
import countryList from '@/json/country.json'

export default {
    data() {
        return {
            token: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            email: '',
            country: '',
            nameCard: '',
            accpeted: false,
            selectedCountry: '',
            countryData: [...countryList]
        };
    },
    computed: {
        stripeElements() {
            return this.$stripe.elements();
        },
    },
    mounted() {
        const style = {
            base: {
                color: 'black',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#aab7c4',
                },
                minHeight: '46px'
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };
        this.cardNumber = this.stripeElements.create('cardNumber', { style, showIcon: true, })
        this.cardNumber.mount('#card-number')
        this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc = this.stripeElements.create('cardCvc', { style })
        this.cardCvc.mount('#card-cvc')
    },
    beforeDestroy() {
        this.cardNumber.destroy()
        this.cardExpiry.destroy()
        this.cardCvc.destroy()
    },
    methods: {
        async createToken() {
            const { token, error } = await this.$stripe.createToken(this.cardNumber)
            if (error) {
                document.getElementById('card-error').innerHTML = error.message
                return
            }
        },
    },
    watch: {
        selectedCountry(newValue, oldValue) {
            console.log("selectedCountry", { newValue, oldValue })
        }
    }
};
</script>

<style>
#card-number {
    border-bottom: 1px solid #d7d7d7;
}

#card-expiry {
    border-right: 1px solid #d7d7d7;
}

#card-number,
#card-expiry,
#card-cvc {
    padding: 7px;
}

.card-group {
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    padding: 2px;
}

#card-error {
    color: red;
    font-size: 14px;
}

.line-heigh {
    line-height: 1.6;
}

.v-input__slot {
    padding: 0 !important;
    box-shadow: none !important;
    border: 1px solid #d7d7d7 !important;
    border-radius: 6px !important;
}

.checkbox {
    border: 1px solid #dbdbdb;
    padding: 9px;
    height: 81px;
    border-radius: 5px;
}

.v-input--checkbox>.v-input__control>.v-input__slot {
    border: none !important;
    border-radius: none !important;
}

input {
    color: black !important;
    padding: 5px !important
}

.v-select-list {
    background-color: white !important;
}
.v-select__selection{
    color:black !important;
    padding: 5px !important
}

.button {
    color: #f1f1f1;
    min-height: 45px;
    font-size: medium;
}
</style>
