<template>
    <div class="w-full h-screen grid grid-cols-2">
        <div class="col-span-1">
            <div class="p-20">
                <div class="flex items-center gap-5">
                    <button @click="handleBack">
                        <img
                            src="@/assets/icons/arrow-left.png"
                            class="arrow-icon"
                            alt="arrow_left"
                        />
                    </button>
                    <img
                        src="@/assets/images/ultimateq-tenant-logo.png"
                        alt="logo"
                        class="logo"
                    />
                </div>
                <div class="mt-5">
                    <div class="p-10">
                        <div class="text-2xl text-white">
                            Subscribe to UltimateQ Basic Plan <br />
                            Subscription
                        </div>
                        <div class="flex">
                            <p class="text-4xl text-white font-semibold">
                                ${{ price }} AUD
                            </p>
                            <div class="flex flex-col ml-2">
                                <span>per</span>
                                <span>month</span>
                            </div>
                        </div>
                        <div>
                            <div v-for="content in contents" class="flex">
                                <p>
                                    <span class="icon color-icon"
                                        ><fa-icon
                                            icon="check"
                                            class="text-green"
                                    /></span>
                                    <span class="ml-2">{{ content }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center pt-10">
                    <p class="tracking-widest">
                        Powered by
                        <span class="font-bold text-lg">Stripe</span> | Term
                        Privacy
                    </p>
                </div>
            </div>
        </div>
        <div class="col-span-1 bg-white">
            <CheckoutForm />
        </div>
    </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import CheckoutForm from "@/components/shared/CheckoutForm.vue";
import { ref } from "@vue/composition-api";

export default {
    components: {
        StripeCheckout,
        CheckoutForm,
    },
    setup(props, { root }) {
        const price = ref("");
        const contents = ref([]);
        const handleBack = () => {
            root.$router.back();
        };
        return {
            handleBack,
            contents,
            price,
        };
    },
};
</script>

<style>
.logo {
    max-height: 48px;
    width: auto;
}
.arrow-icon {
    max-width: 100%;
    height: 20px;
}
</style>
